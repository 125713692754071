import styles from 'app/App.module.scss'
import { AssistantPopover } from 'components/assistant/assistantPopover/AssistantPopover'
import { useHasPermissions } from 'hooks/useHasPermissions'

export function App() {
  const { hasAccessToChatAssistant } = useHasPermissions()

  if (!hasAccessToChatAssistant) {
    return null
  }

  return (
    <div className={styles.assistantContainer}>
      <AssistantPopover />
      <div id="modals" />
    </div>
  )
}
