import { AnalyticsActionType } from '@wpp-open/core'
import clsx from 'clsx'
import React, { cloneElement, useState } from 'react'

import { trackAnalytics } from 'utils/analytics'
import { EVENTS } from 'utils/events'

import styles from './Toolbar.module.scss'
import AppsList from '../apps/AppsList'

export function Toolbar({ button }: { button: React.ReactNode }) {
  const [openToolbar, setOpenToolbar] = useState(false)

  const handleMouseEnter = () => {
    trackAnalytics({
      type: AnalyticsActionType.action,
      payload: EVENTS.ACTIONS.BLUE_BUTTON_OPEN,
    })
    setOpenToolbar(true)
  }

  return (
    <div onMouseEnter={() => handleMouseEnter()} onMouseLeave={() => setOpenToolbar(false)}>
      <div
        className={clsx(styles.toolbarContainer, [openToolbar ? styles.opened : styles.closed])}
        onMouseEnter={() =>
          trackAnalytics({
            type: AnalyticsActionType.action,
            payload: EVENTS.ACTIONS.BLUE_BUTTON_HOVER,
          })
        }
      >
        <AppsList isAiAssistantOpen={false} />
      </div>
      {cloneElement(button as React.ReactElement, {
        className: `${(button as React.ReactElement).props.className} ${
          openToolbar ? styles.toolbarMainButtonExpanded : ''
        }`.trim(),
        children: (
          <>
            {(button as React.ReactElement).props.children}
            <div className={clsx(styles.toolbarWrapper, [openToolbar ? styles.opened : styles.closed])} />
          </>
        ),
      })}
    </div>
  )
}
