import { fetchApps } from 'api/assistant/fetchers/apps/fetchApps'
import { createUseQuery } from 'api/common/createUseQuery'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { AppAPI } from 'types/app/app'

export const useFetchApps = createUseQuery({
  queryKey: ApiQueryKeys.APPS,
  fetcher: fetchApps,
  selector: res => res?.data?.data ?? ([] as AppAPI[]),
})
