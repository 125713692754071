// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LesbI{padding:8px;background-color:var(--wpp-grey-color-000);border-radius:var(--wpp-border-radius-s);box-shadow:var(--wpp-box-shadow-m);position:absolute;height:auto;z-index:2;width:320px;max-height:228px;overflow-y:auto;display:none;flex-direction:column}.LesbI .kYG8V{cursor:pointer;margin-bottom:4px;padding:5px 8px;border-radius:var(--wpp-border-radius-s);display:flex;flex-direction:row;justify-content:space-between}.LesbI .kYG8V:hover{background-color:var(--wpp-grey-color-200)}.LesbI .yppME{background-color:var(--wpp-grey-color-200)}.DGVRt{margin-right:4px}.EsiUZ{font-weight:400}", "",{"version":3,"sources":["webpack://./src/components/assistant/chat/mentions/Mentions.module.scss"],"names":[],"mappings":"AAAA,OACE,WAAA,CACA,0CAAA,CACA,wCAAA,CACA,kCAAA,CACA,iBAAA,CACA,WAAA,CACA,SAAA,CACA,WAAA,CACA,gBAAA,CACA,eAAA,CACA,YAAA,CACA,qBAAA,CAEA,cACE,cAAA,CACA,iBAAA,CACA,eAAA,CACA,wCAAA,CACA,YAAA,CACA,kBAAA,CACA,6BAAA,CAEA,oBACE,0CAAA,CAIJ,cACE,0CAAA,CAIJ,OACE,gBAAA,CAGF,OACE,eAAA","sourcesContent":[".mentionsContainer {\n  padding: 8px;\n  background-color: var(--wpp-grey-color-000);\n  border-radius: var(--wpp-border-radius-s);\n  box-shadow: var(--wpp-box-shadow-m);\n  position: absolute;\n  height: auto;\n  z-index: 2;\n  width: 320px;\n  max-height: 228px;\n  overflow-y: auto;\n  display: none;\n  flex-direction: column;\n\n  .mention {\n    cursor: pointer;\n    margin-bottom: 4px;\n    padding: 5px 8px;\n    border-radius: var(--wpp-border-radius-s);\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n\n    &:hover {\n      background-color: var(--wpp-grey-color-200);\n    }\n  }\n\n  .active {\n    background-color: var(--wpp-grey-color-200);\n  }\n}\n\n.mentionText {\n  margin-right: 4px;\n}\n\n.descriptionText {\n  font-weight: 400;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mentionsContainer": "LesbI",
	"mention": "kYG8V",
	"active": "yppME",
	"mentionText": "DGVRt",
	"descriptionText": "EsiUZ"
};
export default ___CSS_LOADER_EXPORT___;
