import { fetchUserSettings } from 'api/assistant/fetchers/userSettings/fetchUserSettings'
import { createUseQuery } from 'api/common/createUseQuery'
import { AssistantSizes } from 'components/assistant/assistantPopover/assistantUserSettings/AssistantUserSettings'
import { ApiQueryKeys } from 'constants/apiQueryKeys'

export const useFetchUserSettings = createUseQuery({
  queryKey: ApiQueryKeys.USER_SETTINGS,
  fetcher: fetchUserSettings,
  selector: res => res?.data ?? { mentionsEnabled: false, windowSize: AssistantSizes.S, id: '' },
})
