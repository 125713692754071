import { MentionOption } from '../Mentions'

export const filterMentions = (mention: string, mentionOptions: MentionOption[]) => {
  const filteredMentionsArray: { id: string; display: string; description: string }[] = []
  let mentionWithoutAt: string = mention.replace('@', '')

  mentionOptions.forEach((option: MentionOption) => {
    const optionString = option.display.toLowerCase()
    mentionWithoutAt = mentionWithoutAt.trim()

    if (optionString.includes(mentionWithoutAt.toLowerCase())) {
      filteredMentionsArray.push(option)
    }
  })

  return filteredMentionsArray
}

export const mentionDropdownPosition = (inputElement: HTMLElement | null, dropdownElement: HTMLElement | null) => {
  if (!inputElement || !dropdownElement) return

  const selection = window.getSelection()
  const range = selection?.getRangeAt(0)
  const rects = range?.getClientRects()
  if (!rects || rects.length === 0) return
  const rect = rects[0]
  const inputRect = inputElement.getBoundingClientRect()

  const bottom = 142.5 - (rect.top - inputRect.top)

  dropdownElement.style.bottom = `${bottom}px`

  dropdownElement.style.left =
    inputRect.width < 350
      ? '15px'
      : inputRect.width - (rect.left - inputRect.left) < 320
      ? `${inputRect.width - 335}px`
      : `${rect.left - inputRect.left}px`

  dropdownElement.style.display = 'flex'
}
