import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import styles from 'components/assistant/chat/chatBubble/dataAnswer/DataAnswer.module.scss'
import { DataItem } from 'components/assistant/chat/chatBubble/dataAnswer/dataItem/DataItem'
import { OpenAIAnswer } from 'components/assistant/chat/chatBubble/openAIAnswer/OpenAIAnswer'

interface Props {
  data: {
    dataWorldContent?: string
    data_world_content?: string
    superDataContent: string
  }[]
}

export const DataAnswer = ({ data }: Props) => {
  const { t } = useTranslation()

  function convertDataWorldContentToObject(dataWorldContent: string) {
    const regex = /Resource type: (.*?)\nName: (.*?)\nDescription: (.*?)\nTags: \[(.*?)\]\nURL: (.*?)\n/g
    const matches = [...dataWorldContent.matchAll(regex)]
    return matches.map(match => ({
      resourceType: match[1],
      name: match[2],
      description: match[3],
      tags: match[4].split(', ').map((tag: string) => tag.replace(/'/g, '')),
      url: match[5],
    }))
  }

  const convertedData = data.map(item => ({
    dataWorldContent: convertDataWorldContentToObject(item.dataWorldContent || item.data_world_content || ''),
  }))

  const isDataEmpty = useMemo(() => {
    if (!Array.isArray(convertedData)) {
      return true
    }

    for (const item of convertedData) {
      if (!Array.isArray(item.dataWorldContent) || item.dataWorldContent.length > 0) {
        return false
      }
    }

    return true
  }, [convertedData])

  if (isDataEmpty) {
    return <OpenAIAnswer text={t('assisstant_errors.no_data')} />
  }

  return (
    <div className={styles.dataCard}>
      {convertedData.map((dataItem, index) => (
        <div key={index}>
          {dataItem.dataWorldContent.map((item, subIndex) => (
            <DataItem name={item.name} description={item.description} tags={item.tags} url={item.url} key={subIndex} />
          ))}
        </div>
      ))}
    </div>
  )
}
