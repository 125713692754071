import { assistantApi } from 'api'
import { ConversationDto } from 'types/dto/ConversationDto'

export const searchSavedConversations = ({
  userId,
  tenantId,
  searchQuery,
}: {
  userId: string
  tenantId: string
  searchQuery: string
}) =>
  assistantApi.get<ConversationDto[]>('/chats/search', {
    params: { userId, tenantId, searchQuery },
  })
