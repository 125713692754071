import { WppTypography } from '@platform-ui-kit/components-library-react'
import { AnalyticsActionType } from '@wpp-open/core'
import clsx from 'clsx'
import React, { MutableRefObject, useEffect } from 'react'

import styles from 'components/assistant/chat/mentions/Mentions.module.scss'
import { mentionDropdownPosition } from 'components/assistant/chat/mentions/utils/mentions'
import { trackAnalytics } from 'utils/analytics'
import { EVENTS } from 'utils/events'

export interface MentionOption {
  id: string
  display: string
  description: string
}

interface MentionsProps {
  mentionOptions: MentionOption[]
  questionInputRef: MutableRefObject<any>
  selectedMentionId: string
  onClick: (mention: string) => void
  mentionsContainerRef: React.RefObject<HTMLDivElement>
  mentionsRef: MutableRefObject<any>
}

export const Mentions = ({
  mentionOptions,
  questionInputRef,
  selectedMentionId,
  onClick,
  mentionsContainerRef,
  mentionsRef,
}: MentionsProps) => {
  const handleMentionClick = (mention: string) => {
    trackAnalytics({
      type: AnalyticsActionType.action,
      payload: {
        action: EVENTS.ACTIONS.CLICK_MENTIONS,
        params: [{ key: 'pill_text', value: mention }],
      },
    })
    onClick(mention)
  }

  const handleMentionContainerPress = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation()
  }

  useEffect(() => {
    mentionDropdownPosition(questionInputRef.current, mentionsContainerRef.current)
  }, [mentionOptions, questionInputRef, mentionsContainerRef])

  useEffect(() => {
    mentionOptions.forEach(option => {
      if (mentionsRef.current && !mentionsRef.current[option.id]) {
        mentionsRef.current[option.id] = React.createRef()
      }
    })
  }, [mentionOptions, mentionsRef])

  return (
    <div>
      {mentionOptions.length > 0 && (
        <div
          ref={mentionsContainerRef}
          className={styles.mentionsContainer}
          onMouseEnter={() =>
            trackAnalytics({
              type: AnalyticsActionType.action,
              payload: EVENTS.ACTIONS.HOVER_MENTIONS,
            })
          }
          onMouseDown={handleMentionContainerPress}
        >
          {mentionOptions.map(option => (
            <div
              className={clsx(styles.mention, selectedMentionId === option.id && styles.active)}
              key={option.id}
              onClick={() => handleMentionClick(option.display)}
              ref={mentionsRef.current ? mentionsRef.current[option.id] : null}
            >
              <WppTypography className={styles.mentionText} tag="span" type="xs-strong">
                <span className={styles.mentionText}>@{option.display}</span>
                <span className={styles.descriptionText}>{option.description}</span>
              </WppTypography>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
