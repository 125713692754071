import { WppAccordion, WppTypography } from '@platform-ui-kit/components-library-react'
import { Fragment, useMemo } from 'react'

import styles from 'components/assistant/chat/chatBubble/starfishAnswer/StarfishAnswer.module.scss'
import { Flex } from 'components/common/flex/Flex'
import { StarfishAnswerDto } from 'types/dto/ConversationDto'

interface Props {
  answer: StarfishAnswerDto
}

export const StarfishAnswer = ({ answer }: Props) => {
  const { documents, answers } = useMemo(() => {
    const documents = answer.content.documents || []
    const answers = answer.content.answers || []

    return { documents, answers }
  }, [answer])

  const { matched, unmatched } = useMemo(() => {
    const matchedDocs: { answer: { key: string; text: string }; documents: any }[] = []
    const unmatchedDocs = new Map(documents.map((doc: { doc_id: any }) => [doc.doc_id, doc]))

    answers.forEach(answer => {
      const relatedDocuments = documents.filter(document => document.doc_id === answer.key)

      if (relatedDocuments.length > 0) {
        matchedDocs.push({
          answer: answer,
          documents: relatedDocuments,
        })

        relatedDocuments.forEach(doc => unmatchedDocs.delete(doc.doc_id))
      }
    })

    return { matched: matchedDocs || [], unmatched: Array.from(unmatchedDocs.values()) || [] }
  }, [documents, answers])

  return (
    <Fragment>
      {matched.map((data: any) => (
        <div style={{ padding: '12px 16px' }} className={styles.card}>
          <Flex className={styles.chatBubbleQuestion} direction="row" wrap="nowrap" gap={12}>
            <Flex>
              <WppAccordion expanded withDivider={false} size="s">
                <WppTypography type="2xs-strong" slot="header" className={styles.documentType}>
                  {data.documents[0].title}
                </WppTypography>
                <Flex direction="column" gap={12}>
                  <WppTypography tag="p" type="s-body" className={styles.text}>
                    {data.answer.text}
                  </WppTypography>
                  {data.documents.map((document: any) => {
                    return (
                      <a
                        key={document?.title || 'Document'}
                        href={`${document.filePath || document.file_path}${document?.token}`}
                        target="_blank"
                        className={!document ? styles.disabled : undefined}
                        rel="noreferrer"
                      >
                        <div className={styles.cardWithinAccordion}>
                          <Flex gap={12}>
                            <Flex>
                              <img
                                src={`${
                                  document.thumbnailPath
                                    ? document.thumbnailPath + document.token
                                    : document.thumbnail_path
                                    ? document.thumbnail_path + document.token
                                    : document.previewPath || document.preview_path
                                }`}
                                alt={document.title}
                                className={styles.articleImg}
                              />
                            </Flex>
                            <Flex direction="column" className={styles.articleNote} gap={20}>
                              <Flex direction="column">
                                <WppTypography type="2xs-strong" className={styles.documentType}>
                                  Case Study
                                </WppTypography>
                                <WppTypography type="s-midi">{document?.title}</WppTypography>
                              </Flex>
                            </Flex>
                          </Flex>
                        </div>
                      </a>
                    )
                  })}
                </Flex>
              </WppAccordion>
            </Flex>
          </Flex>
        </div>
      ))}
      {unmatched.map((document: any) => (
        <a
          key={document?.title || 'Document'}
          href={`${document.filePath || document.file_path}${document?.token}`}
          target="_blank"
          className={!document ? styles.disabled : undefined}
          rel="noreferrer"
        >
          <div className={styles.card}>
            <Flex gap={12}>
              <Flex>
                <img
                  src={`${
                    document.thumbnailPath
                      ? document.thumbnailPath + document.token
                      : document.thumbnail_path
                      ? document.thumbnail_path + document.token
                      : document.previewPath || document.preview_path
                  }`}
                  alt={document.title}
                  className={styles.articleImg}
                />
              </Flex>
              <Flex direction="column" className={styles.articleNote} gap={20}>
                <Flex direction="column">
                  <WppTypography type="2xs-strong" className={styles.documentType}>
                    Case Study
                  </WppTypography>
                  <WppTypography type="s-midi">{document?.title}</WppTypography>
                </Flex>
              </Flex>
            </Flex>
          </div>
        </a>
      ))}
    </Fragment>
  )
}
