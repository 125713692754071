import {
  WppButton,
  WppDivider,
  WppPill,
  WppPillGroup,
  WppTextareaInput,
  WppTypography,
} from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import styles from 'components/assistant/chat/chatBubble/feedbackForm/FeedbackForm.module.scss'
import { Flex } from 'components/common/flex/Flex'

export enum FeedbackSelectOptions {
  NOT_HELPFUL = 'Not helpful',
  NOT_CORRECT = 'Not correct',
  OTHER = 'Other',
}

export enum FeedbackReaction {
  NEGATIVE = 'negative',
  POSITIVE = 'positive',
}

const FEEDBACK_MAX_LENGTH = 1000

export interface SubmitFeedbackInterface {
  feedbackSelectValue: FeedbackSelectOptions[]
  feedbackComment: string
}

interface Props {
  onCloseFeedback: () => void
  onSubmit: (submitFeedback: SubmitFeedbackInterface) => void
}

export const FeedbackForm = ({ onCloseFeedback, onSubmit }: Props) => {
  const { t } = useTranslation()

  const [feedbackSelectValue, setFeedbackSelectValue] = useState<FeedbackSelectOptions[]>([
    FeedbackSelectOptions.NOT_HELPFUL,
  ])

  const [feedbackComment, setFeedbackComment] = useState('')

  const [submitDisabled, setSubmitDisabled] = useState(false)

  const handlePillGroupChange = (event: CustomEvent) => {
    setFeedbackSelectValue(event.detail.value)
  }

  const onTextareaChange = (event: CustomEvent) => {
    if (event.detail.value.length > FEEDBACK_MAX_LENGTH) {
      setSubmitDisabled(true)
    } else {
      setSubmitDisabled(false)
    }
    setFeedbackComment(event.detail.value)
  }

  useEffect(() => {
    if (feedbackSelectValue?.length === 0) {
      setSubmitDisabled(true)
    } else {
      if (feedbackComment.length > FEEDBACK_MAX_LENGTH) {
        return
      }
      setSubmitDisabled(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feedbackSelectValue])

  const onHandleSubmit = () => {
    const submitFeedback = {
      feedbackSelectValue,
      feedbackComment,
    }
    onSubmit(submitFeedback)
  }

  return (
    <div>
      <Flex direction="column" gap={16}>
        <WppDivider />

        <WppPillGroup type="multiple" value={feedbackSelectValue} onWppChange={handlePillGroupChange}>
          <WppPill
            label={t('feedback.not_helpful')}
            value={FeedbackSelectOptions.NOT_HELPFUL}
            className="cancel-drag"
          />
          <WppPill
            label={t('feedback.not_correct')}
            value={FeedbackSelectOptions.NOT_CORRECT}
            className="cancel-drag"
          />
          <WppPill label={t('feedback.other')} value={FeedbackSelectOptions.OTHER} className="cancel-drag" />
        </WppPillGroup>

        <div>
          <WppTextareaInput
            placeholder={t('feedback.placeholder')}
            value={feedbackComment}
            labelConfig={{
              text: t('feedback.label'),
            }}
            className="cancel-drag"
            onWppChange={onTextareaChange}
          />

          <Flex justify="end" className={styles.questionCharacterLimit}>
            <WppTypography
              type="xs-body"
              className={clsx(
                feedbackComment.length > FEEDBACK_MAX_LENGTH
                  ? styles.questionCharacterLimitLabelError
                  : styles.questionCharacterLimitLabel,
              )}
            >
              {t('question_input.characters_label')}:
            </WppTypography>
            <WppTypography
              type="xs-strong"
              className={clsx(feedbackComment.length > FEEDBACK_MAX_LENGTH && styles.questionCharacterLimitLabelError)}
            >
              &nbsp;{feedbackComment.length}/{FEEDBACK_MAX_LENGTH}
            </WppTypography>
          </Flex>

          <Flex align="center" justify="end" gap={12} className={styles.my8}>
            <WppButton
              variant="secondary"
              size="s"
              color="var(--wpp-primary-color-500)"
              className="cancel-drag"
              onClick={onCloseFeedback}
            >
              {t('common.cancel')}
            </WppButton>
            <WppButton
              variant="primary"
              size="s"
              color="var(--wpp-primary-color-500)"
              disabled={submitDisabled}
              className="cancel-drag"
              onClick={onHandleSubmit}
            >
              {t('feedback.submit')}
            </WppButton>
          </Flex>
        </div>
      </Flex>
    </div>
  )
}
