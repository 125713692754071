import { WppTypography } from '@platform-ui-kit/components-library-react'

import styles from 'components/assistant/chat/chatBubble/brandAnswer/BrandAnswer.module.scss'
import { MarkdownText } from 'components/assistant/chat/chatBubble/markdownText/MarkdownText'
import { Flex } from 'components/common/flex/Flex'

interface Props {
  text: string
}

export const BrandAnswer = ({ text }: Props) => {
  return (
    <Flex className={styles.chatBubbleAnswer} direction="row" wrap="nowrap" gap={12}>
      <WppTypography tag="p" type="s-body" className={styles.text}>
        <MarkdownText text={text} />
      </WppTypography>
    </Flex>
  )
}
