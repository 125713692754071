import clsx from 'clsx'
import React, { KeyboardEvent, FormEvent, MutableRefObject } from 'react'

import styles from 'components/assistant/chat/Chat.module.scss'
import { Mentions } from 'components/assistant/chat/mentions/Mentions'
import { QuestionInput } from 'components/assistant/chat/questionInput/QuestionInput'
import { useChatContext } from 'hooks/useChatContext'
import { useHasPermissions } from 'hooks/useHasPermissions'

interface Props {
  onSubmitQuestion: (event: FormEvent<HTMLFormElement>) => void
  question: string
  answerIsLoading: boolean
  onQuestionKeyDown: (event: KeyboardEvent<HTMLDivElement>) => void
  mentionOptionsMemo: { id: string; display: string; description: string }[]
  questionCharCount: number
  onInput: () => void
  onMouseDown: (event: React.MouseEvent<HTMLDivElement>) => void
  mentionDropdownVisible: boolean
  mentionClicked: (mention: string) => void
  inputRef: React.RefObject<HTMLDivElement>
  defaultValue: string
  selectedMentionId: string
  enableEdit: boolean
  mentionsContainerRef: React.RefObject<HTMLDivElement>
  mentionsRef: MutableRefObject<any>
}

const ChatQuestionInput = ({
  onSubmitQuestion,
  question,
  answerIsLoading,
  onQuestionKeyDown,
  mentionOptionsMemo,
  questionCharCount,
  onInput,
  onMouseDown,
  mentionDropdownVisible,
  mentionClicked,
  defaultValue,
  selectedMentionId,
  inputRef,
  enableEdit,
  mentionsContainerRef,
  mentionsRef,
}: Props) => {
  const { hasAccessToChatAssistantConfig } = useHasPermissions()
  const { questionInputVisible } = useChatContext()

  if (!questionInputVisible) return null

  return (
    <form
      className={clsx(
        styles.questionInputWrapper,
        hasAccessToChatAssistantConfig && styles.questionInputWrapperFooterOffset,
      )}
      onSubmit={onSubmitQuestion}
    >
      <div className={styles.inputBorder}>
        <QuestionInput
          inputRef={inputRef}
          enableEdit={enableEdit}
          defaultValue={defaultValue}
          onInput={onInput}
          onQuestionKeyDown={onQuestionKeyDown}
          onMouseDown={onMouseDown}
          question={question}
          questionCharCount={questionCharCount}
          answerIsLoading={answerIsLoading}
        />
        {mentionOptionsMemo.length > 0 && mentionDropdownVisible && (
          <Mentions
            mentionOptions={mentionOptionsMemo}
            questionInputRef={inputRef}
            selectedMentionId={selectedMentionId}
            onClick={mentionClicked}
            mentionsContainerRef={mentionsContainerRef}
            mentionsRef={mentionsRef}
          />
        )}
      </div>
    </form>
  )
}

export default ChatQuestionInput
