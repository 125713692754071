export const EVENTS = {
  SCREENS: {
    WELCOME_SCREEN: 'aia_pv_welcome_screen',
    HISTORY_SCREEN: 'aia_pv_history_screen',
    SETTINGS_SCREEN: 'aia_pv_settings_screen',
    BLUE_BUTTON: 'aia_pv_bluestart_button_view',
  },
  ACTIONS: {
    MESSAGE_SENT: 'aia_act_message_sent',
    RESPONSE_COPIED: 'aia_act_response_copied',
    RESPONSE_RATED: 'aia_act_response_rated',
    FEEDBACK_SUBMITTED: 'aia_act_feedback_submitted',
    BLUE_BUTTON_CLICK: 'aia_act_bluestart_button_click',
    BLUE_BUTTON_OPEN: 'aia_act_bluestart_modal_view',
    BLUE_BUTTON_HOVER: 'aia_act_bluestart_modal_hover',
    SIDEBAR_EXPAND: 'aia_act_toolbar_menu_hover',
    PILL_CLICK: 'aia_act_chat_pill_click',
    EXIT_CHAT: 'aia_act_chat_exit_button_click',
    NEW_CONVERSATION: 'aia_act_chat_plus_button_click',
    ACTIVATE_MENTIONS: 'aia_act_chat_mention_menu_view',
    CLICK_MENTIONS: 'aia_act_chat_mention_menu_click',
    HOVER_MENTIONS: 'aia_act_chat_mention_menu_hover',
    RESIZE_CHAT: 'aia_act_chat_resize_click',
    APP_LAUNCH: 'app_launch',
  },
}

export const getEventPayload = (event: string, itemName: string) => event.replace('{itemName}', itemName)
