import {
  WppMenuContext,
  WppListItem,
  WppIconMore,
  WppActionButton,
  WppIconPin,
  WppIconShare,
  WppIconTrash,
  WppDivider,
} from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'

import styles from 'components/assistant/savedConversationsList/savedConversationsListItem/savedConversationListItemMenu/SavedConversationListItemMenu.module.scss'
import { ListItemActions } from 'constants/ui'

interface Props {
  pinned: boolean
  onClick: (e: any) => void
  onSelect: (item: ListItemActions) => void
}

export const SavedConversationsListItemMenu = ({ pinned, onSelect, onClick }: Props) => {
  const ListItems = [
    {
      tab: ListItemActions.PIN,
      icon: <WppIconPin slot="left" />,
      label: pinned ? 'Unpin' : 'Pin',
      disabled: false,
      divider: false,
    },
    {
      tab: ListItemActions.SHARE,
      icon: <WppIconShare slot="left" />,
      label: 'Share',
      disabled: true,
      divider: false,
    },
    {
      tab: ListItemActions.DELETE,
      icon: <WppIconTrash slot="left" />,
      label: 'Delete',
      disabled: false,
      divider: true,
    },
  ]

  return (
    <>
      <WppMenuContext className={clsx('cancel-drag', styles.wAuto)} listWidth="160px" onClick={onClick}>
        <WppActionButton slot="trigger-element">
          <WppIconMore direction="horizontal" size="s" />
        </WppActionButton>
        <div>
          {ListItems.map((item, idx) => (
            <div key={idx}>
              {item.divider && <WppDivider />}
              <WppListItem key={idx} disabled={item.disabled} onWppChangeListItem={() => onSelect(item.tab)}>
                {item.icon && item.icon}
                <p slot="label">{item.label}</p>
              </WppListItem>
            </div>
          ))}
        </div>
      </WppMenuContext>
    </>
  )
}
