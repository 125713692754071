export enum ApiQueryKeys {
  SAVED_CONVERSATIONS = 'saved_conversations',
  SAVED_CONVERSATIONS_SEARCH = 'saved_conversations_search',
  CONVERSATION_MESSAGES = 'conversation_messages',
  USER_SETTINGS = 'user_settings',
  CONNECTION_DETAILS = 'connection_details',
  STAR_FISH_DOCUMENTS = 'star_fish_documents',
  TASK_PROGRESS = 'task_progress',
  USER_APPS = 'user_apps',
  APPS = 'apps',
}
