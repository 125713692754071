import { ReactElement, JSXElementConstructor, ReactFragment, ReactPortal } from 'react'
import ReactDOM from 'react-dom'

export const PortalModal = (props: {
  children:
    | string
    | number
    | boolean
    | ReactElement<any, string | JSXElementConstructor<any>>
    | ReactFragment
    | ReactPortal
    | null
    | undefined
}) => {
  const modalRoot = document.getElementById('modal-container')

  if (!modalRoot) return null
  return ReactDOM.createPortal(props.children, modalRoot)
}
