export enum AssistantTabs {
  CHAT = 'CHAT',
  HISTORY = 'HISTORY',
  SETTINGS = 'SETTINGS',
}

export enum ListItemActions {
  PIN = 'PIN',
  SHARE = 'SHARE',
  DELETE = 'DELETE',
}

export enum charLength {
  QUESTION_MAX_LENGTH = 2000,
}
