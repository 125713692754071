import { InputChangeEventDetail } from '@platform-ui-kit/components-library'
import { InputMessageTypes } from '@platform-ui-kit/components-library/dist/types/types/common'
import { WppInputCustomEvent } from '@platform-ui-kit/components-library/loader'
import {
  WppIconClose,
  WppIconSearch,
  WppInput,
  WppSkeleton,
  WppTypography,
} from '@platform-ui-kit/components-library-react'
import { useOs } from '@wpp-open/react'
import clsx from 'clsx'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useFetchSavedConversations } from 'api/assistant/queries/conversation/useFetchSavedConversations'
import { useSearchSavedConversations } from 'api/assistant/queries/conversation/useSearchSavedConversations'
import styles from 'components/assistant/savedConversationsList/SavedConversationsList.module.scss'
import { SavedConversationsListItem } from 'components/assistant/savedConversationsList/savedConversationsListItem/SavedConversationsListItem'
import { Flex } from 'components/common/flex/Flex'
import { ConversationDto } from 'types/dto/ConversationDto'

interface Props {
  onSelectConversation: (conversation: ConversationDto, searchString?: string | undefined) => void
  onDeleteConversation: (conversation: ConversationDto) => void
}

export const SavedConversationsList = ({ onSelectConversation, onDeleteConversation }: Props) => {
  const { t } = useTranslation()
  const { osContext } = useOs()

  const [searchInput, setSearchInput] = useState('')
  const [debouncedInput, setDebouncedInput] = useState('')

  const [searchErrorMessage, setSearchErrorMessage] = useState('')
  const [messageType, setMessageType] = useState<InputMessageTypes | undefined>(undefined)

  const { data: savedConversations, isLoading: isLoadingSavedConversations } = useFetchSavedConversations({
    enabled: debouncedInput.length === 0,
    // TODO change chats_num when pagination is implemented
    params: { chats_num: 1000, userId: osContext.userDetails.id, tenantId: osContext.tenant.id },
  })

  const { data: searchConversations, isLoading: isLoadingSearchConversations } = useSearchSavedConversations({
    enabled: debouncedInput.length > 0,
    params: { userId: osContext.userDetails.id, tenantId: osContext.tenant.id, searchQuery: debouncedInput },
  })

  const handleSearchChange = (event: WppInputCustomEvent<InputChangeEventDetail>) => {
    setSearchInput(event.target.value)
  }

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (searchInput.length === 0 || (searchInput.length >= 3 && searchInput.length <= 20)) {
        setDebouncedInput(searchInput)
        setMessageType(undefined)
        setSearchErrorMessage('')
      } else {
        setDebouncedInput('')
        setMessageType('error')
        setSearchErrorMessage('Search value must be between 3 and 20 characters')
      }
    }, 500)
    return () => clearTimeout(timeoutId)
  }, [searchInput])

  const savedConversationsRecents = useMemo(() => savedConversations.history.slice(0, 5), [savedConversations.history])

  const savedConversationsOlder = useMemo(() => savedConversations.history.slice(5), [savedConversations.history])

  return (
    <div style={{ paddingLeft: '8px' }}>
      <div className={styles.savedConversationsList}>
        <WppInput
          name="search"
          placeholder="Search"
          value={searchInput}
          message={searchErrorMessage}
          messageType={messageType}
          required
          className="cancel-drag"
          onWppChange={handleSearchChange}
        >
          <WppIconSearch slot="icon-start" aria-label="Search icon" />
          {searchInput.length !== 0 && (
            <WppIconClose
              slot="icon-end"
              aria-label="Clear icon"
              onClick={() => {
                setSearchInput('')
                setDebouncedInput('')
              }}
            />
          )}
        </WppInput>

        {(isLoadingSavedConversations || isLoadingSearchConversations) && (
          <div className={styles.savedConversationsListLoading}>
            {[...Array(6)].map((e, i) => (
              <WppSkeleton
                variant="rectangle"
                width="100%"
                height="40"
                className={styles.skeletonListItemPlaceholder}
                key={i}
              />
            ))}
          </div>
        )}
        {!isLoadingSavedConversations && !isLoadingSearchConversations && (
          <div>
            {debouncedInput.length === 0 && (
              <Flex align="center" gap={16} direction="column" className={styles.savedConversationsInner}>
                {savedConversations.pinned.length > 0 && (
                  <div className={styles.maxWFull}>
                    <WppTypography type="2xs-strong" className={styles.subHeader}>
                      {t('history_sections.pinned')}
                    </WppTypography>
                    {savedConversations.pinned.map(conversation => (
                      <SavedConversationsListItem
                        conversation={conversation}
                        onSelectConversation={onSelectConversation}
                        onDeleteConversation={onDeleteConversation}
                        key={conversation.id}
                      />
                    ))}
                  </div>
                )}

                {savedConversationsRecents.length > 0 && (
                  <div className={styles.maxWFull}>
                    <WppTypography type="2xs-strong" className={styles.subHeader}>
                      {t('history_sections.recent')}
                    </WppTypography>
                    {savedConversationsRecents.map(conversation => (
                      <SavedConversationsListItem
                        conversation={conversation}
                        onSelectConversation={onSelectConversation}
                        onDeleteConversation={onDeleteConversation}
                        key={conversation.id}
                      />
                    ))}
                  </div>
                )}

                {savedConversationsOlder.length > 0 && (
                  <div className={styles.maxWFull}>
                    <WppTypography type="2xs-strong" className={styles.subHeader}>
                      {t('history_sections.older')}
                    </WppTypography>
                    {savedConversationsOlder.map(conversation => (
                      <SavedConversationsListItem
                        conversation={conversation}
                        onSelectConversation={onSelectConversation}
                        onDeleteConversation={onDeleteConversation}
                        key={conversation.id}
                      />
                    ))}
                  </div>
                )}
              </Flex>
            )}

            {debouncedInput.length > 0 && (
              <div className={clsx(styles.maxWFull, styles.savedConversationsInner)}>
                {searchConversations.length === 0 && (
                  <WppTypography tag="p" type="xs-body" className={styles.text}>
                    {t('history_sections.search_none')}
                  </WppTypography>
                )}

                {searchConversations.length > 0 && (
                  <WppTypography type="2xs-strong" className={styles.subHeader}>
                    {t('history_sections.search')}
                  </WppTypography>
                )}
                {searchConversations.map(conversation => (
                  <SavedConversationsListItem
                    conversation={conversation}
                    onSelectConversation={conversation => onSelectConversation(conversation, debouncedInput)}
                    onDeleteConversation={onDeleteConversation}
                    key={conversation.id}
                  />
                ))}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}
