// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wllhQ{margin-bottom:12px;padding:12px;background-color:var(--wpp-grey-color-200);border-radius:var(--wpp-border-radius-m)}.BFeMP{margin-bottom:12px;padding:12px;background-color:var(--wpp-grey-color-000);border-radius:var(--wpp-border-radius-m)}.p4Kjn{width:98px;height:98px;border-radius:8px;object-fit:contain}.esYh5{position:relative;width:100%}.kNbaD{cursor:default;pointer-events:none}.BZpyJ{margin-bottom:4px;text-transform:uppercase}", "",{"version":3,"sources":["webpack://./src/components/assistant/chat/chatBubble/starfishAnswer/StarfishAnswer.module.scss"],"names":[],"mappings":"AAAA,OACE,kBAAA,CACA,YAAA,CACA,0CAAA,CACA,wCAAA,CAGF,OACE,kBAAA,CACA,YAAA,CACA,0CAAA,CACA,wCAAA,CAGF,OACE,UAAA,CACA,WAAA,CACA,iBAAA,CACA,kBAAA,CAGF,OACE,iBAAA,CACA,UAAA,CAGF,OACE,cAAA,CACA,mBAAA,CAGF,OACE,iBAAA,CACA,wBAAA","sourcesContent":[".card {\n  margin-bottom: 12px;\n  padding: 12px;\n  background-color: var(--wpp-grey-color-200);\n  border-radius: var(--wpp-border-radius-m);\n}\n\n.cardWithinAccordion {\n  margin-bottom: 12px;\n  padding: 12px;\n  background-color: var(--wpp-grey-color-000);\n  border-radius: var(--wpp-border-radius-m);\n}\n\n.articleImg {\n  width: 98px;\n  height: 98px;\n  border-radius: 8px;\n  object-fit: contain;\n}\n\n.articleNote {\n  position: relative;\n  width: 100%;\n}\n\n.disabled {\n  cursor: default;\n  pointer-events: none;\n}\n\n.documentType {\n  margin-bottom: 4px;\n  text-transform: uppercase;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": "wllhQ",
	"cardWithinAccordion": "BFeMP",
	"articleImg": "p4Kjn",
	"articleNote": "esYh5",
	"disabled": "kNbaD",
	"documentType": "BZpyJ"
};
export default ___CSS_LOADER_EXPORT___;
