export interface PromptsInterface {
  value: string
  label: string
  prompt: string
  tooltip?: string
}

export const PromptsData = [
  {
    value: 'campaign-ideas',
    label: 'Creative campaign ideas',
    prompt:
      'Creative campaign ideas for [Amazon Scribe] in [NYC]. The target audiences are [young creatives and people working in Tech] who earn [below-average income]. These young people are [interested in trends, EVs and technology in general], and they are [the first to try out new things on the market].',
  },
  {
    value: 'improve-text',
    label: 'Improve text',
    prompt:
      'Rewrite text: ["Never stop testing, and your advertising will never stop improving."]. Make it sound [casual and inspiring to be used in the pitch deck using Neil Gaiman\'s style]. Max 100 words.',
  },
  {
    value: 'gen-brief',
    label: 'Generate brief',
    prompt:
      'Generate brief for [Dove] trying to [increase their market share] in [California] and [suggest marketing channels and weight between channels in terms of spending].',
  },
  {
    value: 'improve-desc',
    label: 'Improve product description',
    prompt:
      'You are an AI assistant in eCommerce. Improve the product description: [With a 10.2" 300 ppi glare-free Paperwhite display, Kindle Scribe is perfect for reading and writing, even in direct sunlight. The large display gives you room to take notes and journal, and makes it easy to adjust font size and margin width for improved reading comfort. Writing on Kindle Scribe feels like writing on paper. From the natural grip of the pen in your hand, to the sound you hear when you write, its surface is crafted for the best possible reading and writing experience.] to sound confident, young and futuristic. Use the popular NYC slang and pop-culture vocabulary for this. 100 words max.',
  },
  {
    value: 'herp-story',
    label: 'Create brand hero story',
    prompt:
      'Create a brand hero story for [Ford Bronco] for the [upcoming Superbowl awareness video campaign] in [the US]. [Depict the history and hard work behind the inception of that car and its marvellous design].',
  },
  // {
  //   value: 'gen-ideas',
  //   label: 'Generate ideas',
  //   prompt: 'Generate social media post ideas for [Axe by Unilever] for [Florida]. [Max 20 words].',
  // },
]

export const PromptsDataMentions = [
  {
    value: 'appsuggestion',
    label: '@appsuggestion',
    prompt: '@appsuggestion I need to [generate an image] for my social media post',
    tooltip: 'Locate the perfect app for specific requirements',
  },
  {
    value: 'data',
    label: '@data',
    prompt:
      '@data give me [data sources] to help me understand my [audience] among [health-focused & eco-conscious pet owners and breeders] in [the US].',
    tooltip: 'Access swift and pertinent data insights',
  },
  {
    value: 'campaign',
    label: '@campaign',
    prompt:
      '@campaign Nestle is [planning a new campaign] for [Gen Z] in [Italy], focusing on [cereals and breakfast nutrition].',
    tooltip: 'Acquire comprehensive insights into marketing campaigns',
  },
  {
    value: 'brand',
    label: '@brand',
    prompt: '@brand [Coca-Cola]',
    tooltip: 'Grasp deep insights into various brands',
  },
  // {
  //   label: '@LITinsights',
  //   prompt: '@LITinsights suggest insights for the top 3 segments for this activation relevant for FIFA',
  //   tooltip: 'Get LITinsights',
  // },
  // {
  //   label: '@BEACHdata',
  //   prompt:
  //     '@BEACHdata what are the potential sources of weekly+ growth in the FIFA Women`s Cup activation based on historical data',
  //   tooltip: 'Get BEACHdata',
  // },
]
