// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TekKG{width:100%}.jcHcj::part(typography){color:var(--wpp-grey-color-1000)}.WxhVg::part(typography){color:var(--wpp-grey-color-800)}.Ps7SA::part(label){color:var(--wpp-grey-color-1000);font-size:14px;font-weight:400;line-height:22px}.OvLgy{display:none}", "",{"version":3,"sources":["webpack://./src/components/assistant/assistantPopover/assistantUserSettings/AssistantUserSettings.module.scss"],"names":[],"mappings":"AAAA,OACE,UAAA,CAIA,yBACE,gCAAA,CAKF,yBACE,+BAAA,CAKF,oBACE,gCAAA,CACA,cAAA,CACA,eAAA,CACA,gBAAA,CAIJ,OACE,YAAA","sourcesContent":[".mt16 {\n  width: 100%;\n}\n\n.title {\n  &::part(typography) {\n    color: var(--wpp-grey-color-1000);\n  }\n}\n\n.text {\n  &::part(typography) {\n    color: var(--wpp-grey-color-800);\n  }\n}\n\n.toggle {\n  &::part(label) {\n    color: var(--wpp-grey-color-1000);\n    font-size: 14px;\n    font-weight: 400;\n    line-height: 22px;\n  }\n}\n\n.dNone {\n  display: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mt16": "TekKG",
	"title": "jcHcj",
	"text": "WxhVg",
	"toggle": "Ps7SA",
	"dNone": "OvLgy"
};
export default ___CSS_LOADER_EXPORT___;
