import { fetchConversationMessages } from 'api/assistant/fetchers/conversation/fetchConversationMessages'
import { createUseQuery } from 'api/common/createUseQuery'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { ConversationMessageDto } from 'types/dto/ConversationDto'

export const useFetchConversationMessages = createUseQuery({
  queryKey: ApiQueryKeys.CONVERSATION_MESSAGES,
  fetcher: fetchConversationMessages,
  selector: res => res?.data?.data ?? ([] as ConversationMessageDto[]),
})
