import {
  WppActionButton,
  WppIconAttach,
  WppIconImage,
  WppIconMicOn,
  WppIconSend,
  WppTypography,
} from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { useEffect, useState } from 'react'

import styles from './InputActions.module.scss'
import { charLength } from '../../../../constants/ui'
import { Flex } from '../../../common/flex/Flex'

interface InputActionsProps {
  question: string
  questionCharCount: number
  answerIsLoading: boolean
}

const questionMaxLength = charLength.QUESTION_MAX_LENGTH

const attachButtonAction = () => {
  // console.log('Attach button clicked')
  // todo: implement attach button action
}

const imageButtonAction = () => {
  // console.log('Image button clicked')
}

const micButtonAction = () => {
  // console.log('Mic button clicked')
}

const InputActions = ({ question, questionCharCount, answerIsLoading }: InputActionsProps) => {
  const [sendButtonColor, setSendButtonColor] = useState('var(--wpp-grey-color-600)')

  useEffect(() => {
    const color =
      questionCharCount > 0 && questionCharCount <= questionMaxLength ? '#0014CC' : 'var(--wpp-grey-color-600)'
    setSendButtonColor(color)
  }, [questionCharCount])

  return (
    <Flex justify="between" align="center" className={styles.inputActions}>
      <Flex align="center">
        <WppActionButton disabled onClick={attachButtonAction} className="cancel-drag">
          <WppIconAttach />
        </WppActionButton>
        <WppActionButton disabled onClick={imageButtonAction} className="cancel-drag">
          <WppIconImage />
        </WppActionButton>
        <WppActionButton disabled onClick={micButtonAction} className="cancel-drag">
          <WppIconMicOn />
        </WppActionButton>
      </Flex>
      <Flex align="center">
        <WppTypography
          type="xs-body"
          className={clsx(
            question.length > questionMaxLength
              ? styles.questionCharacterLimitLabelError
              : styles.questionCharacterLimitLabel,
          )}
        >
          &nbsp;{questionCharCount}/{questionMaxLength}
        </WppTypography>
        <WppActionButton
          type="submit"
          className={clsx(styles.submitQuestionButton, 'cancel-drag')}
          disabled={answerIsLoading || questionCharCount === 0 || questionCharCount > questionMaxLength}
        >
          <WppIconSend size="m" color={sendButtonColor} />
        </WppActionButton>
      </Flex>
    </Flex>
  )
}

export default InputActions
