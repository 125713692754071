// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".YX9fh{width:100%;margin-bottom:8px;padding:12px 16px;background-color:var(--wpp-grey-color-200);border-radius:var(--wpp-border-radius-m)}.p9oWX{white-space:pre-line}", "",{"version":3,"sources":["webpack://./src/components/assistant/chat/chatBubble/campaignAnswer/CampaignAnswer.module.scss"],"names":[],"mappings":"AAAA,OACE,UAAA,CACA,iBAAA,CACA,iBAAA,CACA,0CAAA,CACA,wCAAA,CAGF,OACE,oBAAA","sourcesContent":[".chatBubbleAnswer {\n  width: 100%;\n  margin-bottom: 8px;\n  padding: 12px 16px;\n  background-color: var(--wpp-grey-color-200);\n  border-radius: var(--wpp-border-radius-m);\n}\n\n.text {\n  white-space: pre-line;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"chatBubbleAnswer": "YX9fh",
	"text": "p9oWX"
};
export default ___CSS_LOADER_EXPORT___;
