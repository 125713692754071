import { WppProgressIndicator, WppTypography } from '@platform-ui-kit/components-library-react'
import { memo, useEffect, useState } from 'react'

import styles from 'components/assistant/chat/answerLoadingPlaceholder/AnswerLoadingPlaceholder.module.scss'
import { Flex } from 'components/common/flex/Flex'
import { StepEventDto, SubtaskStepDto } from 'types/dto/TaskDto'

interface Props {
  allSteps: SubtaskStepDto[]
  finishedSteps: StepEventDto[]
}

const EmptyStep = { stepId: '', description: '' }
const LastStep = { stepId: '', description: 'Finishing up' }

export const AnswerLoadingPlaceholder = memo(({ allSteps, finishedSteps }: Props) => {
  const [step, setStep] = useState(1)

  const [percentage, setPercentage] = useState(0)
  const [percentageShown, setPercentageShown] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      if (percentageShown < percentage) {
        setPercentageShown(prev => prev + 1)
      }
    }, 10)
    return () => clearInterval(interval)
  }, [percentage, percentageShown])

  useEffect(() => {
    if (finishedSteps.length >= step) {
      setStep(prev => prev + 1)
      setPercentage(Math.floor((step / allSteps.length) * 100))
    } else if (finishedSteps.length > 0 && finishedSteps.length === allSteps.length) {
      setPercentage(100)
    } else if (allSteps.length !== 0 && finishedSteps.length === 0) {
      setPercentage(Math.floor(((step / allSteps.length) * 100) / 2))
    }
  }, [finishedSteps.length, step, percentage, allSteps.length])

  return (
    <Flex className={styles.chatBubbleAnswer} align="start" direction="column" wrap="nowrap" gap={16}>
      <WppProgressIndicator className={styles.progressBar} variant="bar" isShowPercentage value={percentageShown} />
      <div className={styles.textSlider}>
        <div className={styles.textWrapper} style={{ transform: `translateY(-${(step - 1) * 32}px)` }}>
          {(allSteps.length ? [EmptyStep, ...allSteps, LastStep] : [EmptyStep, ...allSteps]).map((step, index) => (
            <div key={`${step.stepId}-${index}`} className={styles.text}>
              <WppTypography type="xs-body" className={styles.textInner}>
                {step.description}
              </WppTypography>
            </div>
          ))}
        </div>
      </div>
    </Flex>
  )
})
