import { assistantApi } from 'api'
import { AssistantSizes } from 'components/assistant/assistantPopover/assistantUserSettings/AssistantUserSettings'

export interface Props {
  settingsId: string
  windowSize?: AssistantSizes
  mentionsEnabled?: boolean
}

export const patchUserSettings = ({ settingsId, ...body }: Props) => assistantApi.patch(`/settings/${settingsId}`, body)
