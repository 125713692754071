// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wp3Xk{cursor:text}.I5bVC{cursor:pointer}.s_pew{margin-bottom:8px}.D1U5B{cursor:not-allowed}.EN_28{margin-bottom:8px}", "",{"version":3,"sources":["webpack://./src/components/assistant/chat/chatBubble/ChatBubble.module.scss"],"names":[],"mappings":"AAAA,OACE,WAAA,CAGF,OACE,cAAA,CAGF,OACE,iBAAA,CAGF,OACE,kBAAA,CAGF,OACE,iBAAA","sourcesContent":[".selectableText {\n  cursor: text;\n}\n\n.actionButton {\n  cursor: pointer;\n}\n\n.actionRow {\n  margin-bottom: 8px;\n}\n\n.disabled {\n  cursor: not-allowed;\n}\n\n.notifyButton {\n  margin-bottom: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selectableText": "wp3Xk",
	"actionButton": "I5bVC",
	"actionRow": "s_pew",
	"disabled": "D1U5B",
	"notifyButton": "EN_28"
};
export default ___CSS_LOADER_EXPORT___;
