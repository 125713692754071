import segmentPlugin from '@analytics/segment'
import { AnalyticsAction, AnalyticsPageAction, AnalyticsTrackAction, AnalyticsActionType } from '@wpp-open/core'
import Analytics from 'analytics'

import { segmentAnalyticsKey } from 'constants/segmentConfig'
import { excludeFalsy, isString } from 'utils/common'

export const analytics = Analytics({
  app: 'wpp-ai-assistant',
  plugins: [
    segmentPlugin({
      writeKey: segmentAnalyticsKey,
    }),
  ].filter(excludeFalsy),
})

export const reset = () => {
  analytics.reset()
}

const getAnalyticsTenantAzId = () => analytics?.getState()?.user?.traits?.tenantAzId

const trackPage = ({ payload }: AnalyticsPageAction) => {
  const analyticsTenantAzId = getAnalyticsTenantAzId()
  if (analyticsTenantAzId) {
    analytics.page(
      {
        title: payload,
      },
      {
        agency: analyticsTenantAzId,
      },
    )
  }
}

const trackAction = ({ payload }: AnalyticsTrackAction) => {
  const analyticsTenantAzId = getAnalyticsTenantAzId()
  if (analyticsTenantAzId) {
    if (isString(payload)) {
      analytics.track(payload, {
        agency: analyticsTenantAzId,
      })
    } else {
      const { action, params } = payload

      analytics.track(action, {
        agency: analyticsTenantAzId,
        ...Object.fromEntries(params.map(({ key, value }) => [key, value])),
      })
    }
  }
}

export const trackAnalytics = (action: AnalyticsAction) => {
  // If you want to disable analytics in development, you can use the following line:
  // if (process.env.DEV) return
  switch (action.type) {
    case AnalyticsActionType.page:
      trackPage(action)
      break

    case AnalyticsActionType.action:
      trackAction(action)
      break

    default:
      break
  }
}
