import { assistantApi } from 'api'
import { CancelableRequestProducer } from 'api/common/types'
import { FeedbackReaction, FeedbackSelectOptions } from 'components/assistant/chat/chatBubble/feedbackForm/FeedbackForm'
import { FeedbackDto } from 'types/dto/ConversationDto'

export interface Props {
  feedback_message_id: string
  reaction?: FeedbackReaction
  subTypes?: FeedbackSelectOptions[] | null
  feedback?: string | null
}

export const patchFeedback = ({ feedback_message_id, ...body }: Props): CancelableRequestProducer<FeedbackDto> =>
  assistantApi.patch(`/feedback-messages/${feedback_message_id}`, body)
